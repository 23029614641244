html, body { height: 100%; }

body {
  position: relative;

  font-family: Arial, Helvetica, sans-serif;

  background-color: #000;
  background-position: center center;
  background-size: cover;
  background-image: url('../assets/pizza-infinity-tunnel.gif');
}

.wrapper {
  width: 100%;
  height: 100%;

  background: rgba(0,0,0,0.8);
}

#Bitch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 80%;
  height: 40%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  // background: url('//media.giphy.com/media/prA6olJdnIDKg/giphy.gif'); // spinner
  // background: url('//media.giphy.com/media/3og0IILScwtNUFqGbK/giphy.gif'); // get me
  // background: url('./assets/pizza-spinner.gif');
  background-image: url('../assets/pizza-getme-bitch.gif');
}